<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>التحويلات البنكية</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">
                                            التحويلات البنكية
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="my-invoices">
                                <div class="my-invoices">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <button
                                                class="btn btn-info w-100"
                                                @click="resetDates()"
                                            >
                                                <i class="la la-refresh"></i>
                                            </button>
                                        </div>

                                        <div class="col-md-4">
                                            <input
                                                id="from"
                                                placeholder="من"
                                                class="form-control"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <input
                                                id="to"
                                                placeholder="إلى"
                                                class="form-control"
                                            />
                                        </div>
                                        <div class="col-md-3 text-center">
                                            <h4>{{ transferes }} ريال</h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="inner-container clearfix">
                                    <div class="invoice-box">
                                        <div class="table-responsive">
                                            <table
                                                id="transferes-table"
                                                class="table table-striped table-bordered table-hover"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>القيمة</th>
                                                        <th>تاريخ التحويل</th>
                                                        <th>الوصل</th>
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import flatpickr from "flatpickr";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import "@/plugins/datatables";
import $ from "jquery";

export default {
    name: "my.bank-transferes",
    data() {
        return {
            table: null,
            transferes: 0,
        };
    },
    mounted() {
        this.prepareTable();
        this.getTransferes();

        flatpickr("#from", {
            locale: Arabic,
            onChange: () => {
                this.getTransferes();
                this.updateTable();
            },
        });
        flatpickr("#to", {
            locale: Arabic,
            onChange: () => {
                this.getTransferes();
                this.updateTable();
            },
        });
    },
    methods: {
        resetDates() {
            $("#from").val("");
            $("#to").val("");
            this.getTransferes();
            this.updateTable();
        },
        getTransferes() {
            this.$axios
                .get("/my/calculate-transferes", {
                    params: {
                        from: $("#from").val(),
                        to: $("#to").val(),
                    },
                })
                .then((res) => {
                    this.transferes = res.data.totalTransferes;
                });
        },
        prepareTable() {
            this.table = $("#transferes-table")
                .DataTable({
                    ajax: {
                        url:
                            process.env.VUE_APP_API_BASE_URL +
                            "/my/bank-transferes",
                        data: (d) => {
                            d.from = $("#from").val();
                            d.to = $("#to").val();
                        },
                        crossDomain: true,
                        xhrFields: { withCredentials: true },
                        beforeSend: () => {
                            this.$loading.show();
                        },
                    },
                    serverSide: true,
                    stateSave: true,
                    columns: [
                        { data: "id" },
                        { data: "amount" },
                        { data: "transfer_date" },
                        {
                            name: "images",
                            data: null,
                            render: (row) => {
                                let images = "";

                                row.images.forEach((img) => {
                                    images += `<a href="${img.image}" target="_blank" /><img class="img img-thumbnail" src="${img.image}" /></a>`;
                                });

                                return images;
                            },
                            searchable: false,
                            orderable: false,
                        },
                    ],
                })
                .on("draw", () => {
                    this.$loading.hide(0);
                    $("[v-on]").each((index, element) => {
                        let ob = ((attr) => ({
                            event: /(\w+),/.exec(attr)[1],
                            handler: /, ?(.*)/.exec(attr)[1],
                        }))($(element).attr("v-on"));
                        let f = /(\w+)\(?/.exec(ob.handler)[1];
                        let args = /\w\((.*)\)/
                            .exec(ob.handler)[1]
                            .split(",")
                            .map((v) => v.trim());
                        $(element).on(ob.event, () => {
                            this[f].apply(this, args);
                        });
                    });
                });
        },
        updateTable() {
            this.table.ajax.reload(null, false);
        },
    },
};
</script>

<style>
@import "https://cdn.datatables.net/1.10.22/css/jquery.dataTables.min.css";
@import "../../../../../node_modules/flatpickr/dist/flatpickr.min.css";
</style>